import React, { Component, Fragment } from 'react';
import Header from './Header';
import MainMiddle from './MainMiddle';
import Map from './Map';
import Contact from './Contact';
import Footer from './Footer';
import ImageModal from './ImageModal';
import { updateMax } from './DumbFunctions';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            possibleMainMenus: ['Olovne plombe', 'Plastične plombe', "Žice za plombiranje", "Kliješta za plombiranje", "Kupci", "O nama"],
            selectedMainMenu: 'Olovne plombe',
            selectedSecondaryMenu: 'Olovne',
            secondaryMenuVisibility: false,
            imagesCount: 0,
            imagesMax: 5,
            isModalOpen: false
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.modalKeyboard, false);
    }


    handleCount = (e, type) => {
        e.stopPropagation();
        if (type === "asc") {
            let newCount = this.state.imagesCount;
            if (newCount < this.state.imagesMax) {
                newCount++;
            }
            else {
                newCount = 0;
            }
            this.setState({
                imagesCount: newCount
            })
        }
        else if (type === "desc") {
            let newCount = this.state.imagesCount;
            if (newCount !== 0) {
                newCount--;
            }
            else {
                newCount = this.state.imagesMax;
            }
            this.setState({
                imagesCount: newCount
            })
        }
    }

    handleMainMenuClick = (e) => {
        if (e.target.id !== "menu-items-container") {
            this.setState({
                selectedMainMenu: e.target.innerHTML,
                imagesCount: 0,
                imagesMax: updateMax(e.target.innerHTML, this.state.selectedSecondaryMenu)
            })
        }
    }

    handleMenuHoverIn = (e) => {
        const text = e.target.innerHTML;
        if (text === "Plombe" || text === "Olovne" || text === "Plastične") {
            this.setState({
                secondaryMenuVisibility: true
            })
        }
        else {
            this.setState({
                secondaryMenuVisibility: false
            })
        }
    }

    handleMenuHoverOut = (e) => {
        this.setState({
            secondaryMenuVisibility: false
        })
    }

    handleSecondaryMenuClick = (e) => {
        e.stopPropagation();
        if (e.target.id !== "secondary-menu-container") {
            this.setState({
                selectedSecondaryMenu: e.target.innerHTML,
                imagesCount: 0,
                imagesMax: updateMax("Plombe", e.target.innerHTML),
                selectedMainMenu: 'Plombe',
                isModalOpen: false
            })
        }
    }

    modalKeyboard = (e) => {
        if (this.state.isModalOpen) {
            if (e.keyCode === 37) {
                this.handleCount(e, 'desc');
            }
            else if (e.keyCode === 39) {
                this.handleCount(e, 'asc');
            }
            else if(e.keyCode === 27){
                this.leaveFullscreen(e);
            }
        }
    }

    imgToFullScreen = (e) => {
        window.scroll(0, 0);
        document.body.classList.add("stop-scrolling");
        this.setState({
            isModalOpen: true
        })
    }

    leaveFullscreen = (e) => {
        e.stopPropagation();
        document.body.classList.remove("stop-scrolling");
        this.setState({
            isModalOpen: false
        })
    }

    render() {
        return (
            <Fragment>
                {this.state.isModalOpen ? <ImageModal handleCount={this.handleCount}
                    handleMainMenuClick={this.handleMainMenuClick}
                    handleSecondaryMenuClick={this.handleSecondaryMenuClick}
                    imagesCount={this.state.imagesCount}
                    leaveFullscreen={this.leaveFullscreen}
                    selectedMainMenu={this.state.selectedMainMenu}
                    selectedSecondaryMenu={this.state.selectedSecondaryMenu} /> : null}
                <Header />
                <MainMiddle
                    handleCount={this.handleCount}
                    handleMainMenuClick={this.handleMainMenuClick}
                    handleMenuHoverIn={this.handleMenuHoverIn}
                    handleMenuHoverOut={this.handleMenuHoverOut}
                    handleSecondaryMenuClick={this.handleSecondaryMenuClick}
                    imgToFullScreen={this.imgToFullScreen}
                    imagesCount={this.state.imagesCount}
                    possibleMainMenus={this.state.possibleMainMenus}
                    possibleSecondaryMenus={this.state.possibleSecondaryMenus}
                    selectedMainMenu={this.state.selectedMainMenu}
                    selectedSecondaryMenu={this.state.selectedSecondaryMenu}
                    secondaryMenuVisibility={this.state.secondaryMenuVisibility}
                />
                <div id="contact-map-container">
                    <Map />
                    <Contact />
                </div>
                <Footer />

            </Fragment>
        );
    }
}

export default Home;