import React from 'react';

const Map = () => {

let setMapDimensions = (type) => {
    let wid,hei;
    if(window.innerWidth > 1596){
        wid = "545";
        hei = "545";
    }
    else if(window.innerWidth < 1596 && window.innerWidth > 1100){
        wid = "445";
        hei = "445";
    }
    else if(window.innerWidth < 1100 && window.innerWidth > 768){
        wid = "395";
        hei = "395";
    }

    else if(window.innerWidth < 768 && window.innerWidth > 480){
        wid = "295";
        hei = "295";
    }
    else {
        wid = "300";
        hei = "300";
    }

    if(type === "w"){
        return wid;
    }
    else{
        return hei;
    }
}

    return (
        <div id="map-container">
            <p>GDJE SE NALAZIMO?</p>
            <div id="google-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d984.0969725293091!2d18.12786001275183!3d44.10383184210331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475f27225f8d4cd9%3A0x6d148f93edb66e10!2sTehnika%20d.o.o.!5e0!3m2!1sen!2sba!4v1588862691071!5m2!1sen!2sba"
            width={setMapDimensions("W")} height={setMapDimensions("H") } title="google-mapa"
            frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
        </div>
    );
};

export default Map;