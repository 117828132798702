import React from 'react';

const Footer = () => {
    return (
        <div id="footer-container">
            <div>
            <p>Identifikacioni broj: 4218113430001</p>
            <p>Broj poreznog obveznika: 05280549</p>
            </div>
        </div>
    );
};

export default Footer;