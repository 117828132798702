import React from 'react';
import TehnikaLogo3 from '../images/TehnikaLogo3.png';

const Header = () => {
    const newDate = new Date();
    const year = newDate.getFullYear();
    const difference = year - 1993;
    return (
        <div id="logo-container">
        <div className="left-container">
   <img src={TehnikaLogo3} alt="logo-tehnika"/>
        </div>
        <div className="right-container">
        <p>{difference} GODINA POSLOVANJA, TRADICIJE I KVALITETE</p>
        </div>
        </div>
    );
};

export default Header;