import React from 'react';
import texts from './Texts'

const TextContainer = (props) => {
    let currentTextValue;

    switch(props.selectedMainMenu){
        case 'Olovne plombe':
         currentTextValue = texts.olovnePlombe;
        break;

        case "Plastične plombe":
        currentTextValue = texts.kodiranePlombe;
        break;

        case 'Žice za plombiranje':
        currentTextValue = texts.zicaZaPlombiranje;
        break;

        case 'Kliješta za plombiranje':
        currentTextValue = texts.klijestaZaPlombiranje;
        break;

        case 'O nama':
        currentTextValue = texts.oFirmi
        break;

        case 'Kupci':
        currentTextValue = texts.kupci;
        break;
        
        default:
        currentTextValue = texts.faliTekst
    }

    let lista = texts.listaKupaca.map(kupac => {
        return (
            <li key={kupac}>
                {kupac}
            </li>
        )
    })

    return (
        <div id={`${props.selectedMainMenu === "O nama" ? "text-container-buyers" : "text-container"}`}>
            <div className="main-paragraph">
    {props.selectedMainMenu === "Kupci" ? <div id="bold-buyers">{currentTextValue}</div> : currentTextValue}
                {props.selectedMainMenu === "Kupci" ?
                 <ul>
                     {lista}
                 </ul>
                 : null}
            </div>
        </div>
    );
};

export default TextContainer;