import React from 'react';
import SecondaryMenuItems from './SecondaryMenuItems';

const MenuItems = (props) => {
    const menus = props.possibleMainMenus.map(menuItem => {
        return(
        <p className={`main-menu-item underline ${props.selectedMainMenu === menuItem ? "selected-menu-item" : ""}`} key={menuItem}>
            {menuItem}
            </p>
        )
    })

    return (
        <div id="menu-items-container" 
        onClick={props.handleMainMenuClick} 
        onMouseOver={props.handleMenuHoverIn}
        onMouseLeave={props.handleMenuHoverOut}
        >
            {menus}
            {props.secondaryMenuVisibility ?       <SecondaryMenuItems
            possibleSecondaryMenus={props.possibleSecondaryMenus}
            selectedSecondaryMenu={props.selectedSecondaryMenu}
            handleSecondaryMenuClick={props.handleSecondaryMenuClick}
            handleMenuHoverIn={props.handleMenuHoverIn}
            handleMenuHoverOut={props.handleMenuHoverOut}
            
            /> : null}
        </div>
    );
};

export default MenuItems;