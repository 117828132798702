import React, { Fragment } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import imagesObject from './Images';

const PictureContainer = (props) => {
    let imgSource = "";
    let altText= "Token text";
    let pictureTitle = "Token text";

    switch(props.selectedMainMenu){
        case "Olovne plombe":
            imgSource = imagesObject.plombe.olovne[props.imagesCount].src;
            altText = imagesObject.plombe.olovne[props.imagesCount].alt;
            pictureTitle = imagesObject.plombe.olovne[props.imagesCount].title;
        break;
          
        case "Plastične plombe":
            imgSource = imagesObject.plombe.kodirane[props.imagesCount].src;
            altText = imagesObject.plombe.kodirane[props.imagesCount].alt;
            pictureTitle = imagesObject.plombe.kodirane[props.imagesCount].title;
            break;

        case "Žice za plombiranje":
            imgSource = imagesObject.zice[props.imagesCount].src;
            altText = imagesObject.zice[props.imagesCount].alt;
            pictureTitle = imagesObject.zice[props.imagesCount].title;
            break;

        case "Kliješta za plombiranje":
            imgSource = imagesObject.klijesta[props.imagesCount].src;
            altText = imagesObject.klijesta[props.imagesCount].alt;
            pictureTitle = imagesObject.klijesta[props.imagesCount].title;
            break;

        case "O nama":
            imgSource = imagesObject.ofirmi[props.imagesCount].src;
            altText = imagesObject.ofirmi[props.imagesCount].alt;
            pictureTitle = imagesObject.ofirmi[props.imagesCount].title;
            break;

        case "Kupci":
            imgSource = "";
            altText = "alt";
            pictureTitle = "Neki tekst"
        break;

        default:
        return false;
    }

    return (
        <Fragment>
                    {props.selectedMainMenu !== "Kupci" ? 
        <div id="picture-container">
            <div id="left-carousel">
            <FontAwesomeIcon 
                icon={faChevronLeft} onClick={e => props.handleCount(e,'desc')}/>
                
            </div>
            <div id="picture-holder">
                <img src={imgSource} alt={altText} onClick={props.imgToFullScreen} />
                <div id="picture-title">
    <p>{pictureTitle}</p>
                </div>
            </div>
            <div id="right-carousel">
            <FontAwesomeIcon 
                icon={faChevronRight} onClick={e => props.handleCount(e,'asc')}
              />
            </div>
        </div>
        : null
    }
        </Fragment>
    );
};

export default PictureContainer;