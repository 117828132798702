import React from 'react';

const Contact = () => {
    return (
        <div id="contact-container">
             <p id="contact-title">Kontaktirajte nas</p>
             <div id="contact-fields-container">
            <div id="contact-left">

            <div><p>Mobitel:</p></div>
            <div><p>Email</p></div>
            <div><p>Adresa</p></div>
            </div>
            
            <div id="contact-right">
            <div><p>061 162 391</p></div>
            <div><p>tehnika@kakanj.ba</p></div>
            <div><p>Hrasno bb, Kakanj</p></div>
            </div>
            </div>
        </div>
    );
};

export default Contact;