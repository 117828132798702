let kodiranePlombe= "Kodirane plastične plombe radimo u više varijanti: sa integrisanom žicom u muški dio plombe, dužine žice po potrebi, i bez žice. Kodiranje možemo direktno vlastitim laserom gravirati na plombu, kao i nalijepiti naljepnicu. Ženski dio obično radimo prozirno od makrolona transparenta Dimenzije: 20x18x6 mm. Sastoji se od tri dijela, utičnice, utikača i integrisane žice u tijelo utikača. Tijelo utičnice je od providnog polikarbonata UV stabiliziranog i sa osobinama po ISO 527. Otoporan je na temperaturama 0d -20 do +50 Celzijusa. Žica može biti bakrena, meka čelična pocinčana, silikonska, prekidne sile 50N. Kada se plomba 'ušteka', ne postoji mogućnost rastavljanja bez vidljivih oštećenja. Oznaka na plombi je urađena UV štampom, odlično vidljiva i čitljiva barcod čitaćem.Boje se biraju po zahtjevu a obično se rade plava, žuta, crvena, zelena, crna i bijela.";
let klijestaZaPlombiranje= "Kliješta za plombiranje radimo tip KZP 125. Ona su namijenjena za terensku upotrebu zbog svoje veličine i kompaktnosti jer se mogu nositi i u džepu, ona originalno pokrivaju tri veličine plombi: Ø8mm, Ø10mm, Ø12mm, prečnik žiga je 10mm. Graviranje sa svim mogućnostima graviranja loga vršimo na vlastitoj mehaničkoj mašini što garantuje 100% neponovljivost gravure. Također složenije gravure radimo i na laserskoj mašini.";
let zicaZaPlombiranje= "Žica za plombiranje je proizvod kojeg radimo strukiranjem dvije niti debljine 0,3 mm oko niti 0,6 mm pa je debljina 1,2 mm (2 x 0,3 = 0,6 + 0,5 = 1,2mm) Žicu namotavamo na kaleme koje sami radimo od po 1 kg i na njemu ima 320 metara namotane žice, isto tako žicu režemo na automatskoj rezačici na potrebnu dužinu po zahtjevu kupca i pakujemo u vrećice od po 500 komada ili drugačije. Naša čelična (Fe) meka žica je površinski zaštićena toplocinčana i garantirano je otporna na koroziju. Radimo i bakarnu (Cu) na isti način kao i kombinaciju Bakar-Čelik (Fe-Cu) i kombinacije sa silikonskom žicom.";
let kupci= "Neki od naših cijenjenih kupaca: ";
let olovnePlombe = "Olovne plombe su najbolji način sigurnosnog plombiranja jer je žig koji se ugravira na pečatu klijenta neponovljiv, a sama plomba nije podložna ni oštećenju ni koroziji. Nedostatak olovne plombe je nemogućnost upisivanja serijskog broja."
let faliTekst = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`
let listaKupaca = [
"Igman d.d. , Konjic",
"JP ELEKTROPRIVREDA BIH - ELEKTRODISTRIBUCIJE: Sarajevo, Zenica, Tuzla, Bihać, Mostar, Goražde", 
"JP 'BH POŠTE': Sarajevo, Zenica, Tuzla, Bihać, Travnik, Mostar", 
"R&S  d.o.o. Sarajevo, Vogošća",
"KJKP VIK Sarajevo",
"Elektrobijeljina, Bijeljina",
"Elektrokrajina, Banja Luka",
"Laki-line d.o.o. , Istočno Sarajevo",
"'RMU' Kakanj d.o.o.",
"'PRIMAROM d.o.o.', Banja Luka",
"'DELTAPETROL d.o.o.', Kakanj",
"'INSPEKT RGH d.o.o.', Sarajevo",
"POWERDIS d.o.o. , Bihać",
"Sarajevo gas	Sarajevo",
"'Modrica Oil' Modriča",
"'Violeta' Tomislavgrad", 
"MLIJEKOPRODUKT d.o.o. , Kozarska Dubica",
"'Kakanj cement d.d', Kakanj", 
"'Salon bankarske opreme', Sarajevo", 
"'Euroinspekt RS' Doboj", 
"'Inspekt RS' Bijeljina", 
"INVING d.o.o.	Prijedor",
"VATROSISTEMI d.o.o. ,Sarajevo",
"ARGETA d.o.o.	Hadžići",
"Defter d.o.o.	Sarajevo",
"Samer & Co. Shipping	Brčko"
]


export default {
    kodiranePlombe,
    klijestaZaPlombiranje,
    zicaZaPlombiranje,
    kupci,
    olovnePlombe,
    listaKupaca,
    faliTekst
}