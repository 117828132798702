import zice1 from '../images/zice1.jpg'
import zice2 from '../images/zice2.jpg'
import zice3 from '../images/zice3.jpg'
import zice4 from '../images/zice4.jpg'
import zice5 from '../images/zice5.jpg'
import zice6 from '../images/zice6.jpg'
import zice7 from '../images/zice7.jpg'
import zice8 from '../images/zice8.jpg'
import klijesta1 from '../images/klijesta1.jpg';
import klijesta2 from '../images/klijesta2.JPG';
import olovnePlombe1 from '../images/olovnePlombe1.jpg';
import olovnePlombe2 from '../images/olovnePlombe2.JPG';
import olovnePlombe3 from '../images/olovnePlombe3.jpg';
import olovnePlombe4 from '../images/olovnePlombe4.jpg';
import olovnePlombe5 from '../images/olovnePlombe5.jpg';
import olovnePlombe6 from '../images/olovnePlombe6.jpg';
import kodiranePlombe1 from '../images/kodiranePlombe1.JPG';
import kodiranePlombe2 from '../images/kodiranePlombe2.JPG';
import kodiranePlombe3 from '../images/kodiranePlombe3.JPG';
import kodiranePlombe4 from '../images/kodiranePlombe4.JPG';
import kodiranePlombe5 from '../images/kodiranePlombe5.jpg';
import kodiranePlombe6 from '../images/kodiranePlombe6.jpg';
import kodiranePlombe7 from '../images/kodiranePlombe7.JPG';
import kodiranePlombe8 from '../images/kodiranePlombe8.jpg';
import kodiranePlombe9 from '../images/kodiranePlombe9.jpg';

const imagesObject = {
    plombe: {
        olovne:[
        {src:olovnePlombe1, alt:"Olovne plombe 1", title:"Olovna plomba"}, 
        {src:olovnePlombe2, alt : "Olovne plombe 2", title:"Različite veličine"}, 
        {src:olovnePlombe3, alt:"Olovne plombe 3", title:"Različite veličine"},
        {src:olovnePlombe4, alt:"Olovne plombe 4", title:"T plomba pakovanje"},
        {src:olovnePlombe5, alt:"Olovne plombe 5", title:"Pakovanje obične olovne plombe"},
        {src:olovnePlombe6, alt:"Olovne plombe 6", title:"Radimo i veće narudžbe"},
    ],
        kodirane:[
            {src:kodiranePlombe1, alt:"Kodirane plombe 1", title:"Set plastičnih kodiranih plombi"}, 
            {src:kodiranePlombe2, alt:"Kodirane plombe 2", title:"Plastična plomba 1"}, 
            {src:kodiranePlombe3, alt:"Kodirane plombe 3", title:"Plastična plomba 2"}, 
            {src:kodiranePlombe4, alt:"Kodirane plombe 4", title:"Plastična plomba 3"}, 
            {src:kodiranePlombe5, alt:"Kodirane plombe 5", title:"Plastična plomba 4"}, 
            {src:kodiranePlombe6, alt:"Kodirane plombe 6", title:"Plastična plomba 5"}, 
            {src:kodiranePlombe7, alt:"Kodirane plombe 7", title:"Set plastičnih kodiranih plombi"},
            {src:kodiranePlombe8, alt:"Kodirane plombe 8", title:"Laserski graver"},
            {src:kodiranePlombe9, alt:"kodirane plombe 9", title:"Ekstruder"}
        ]
    },
    zice:[
        {src: zice1, alt:"Namotana žica 1", title:"Žice za plombiranje - sječena"}, 
        {src: zice2, alt: "Namotana žica 2", title:"Žice za plombiranje - sječena"},
        {src: zice3, alt:"Namotana žica 3", title:"Pakovanje žica za plombiranje - sječena"}, 
        {src: zice4, alt: "Namotana žica 4", title:"Čelična, bakrena i silikonska žica"},
        {src: zice5, alt:"Namotana žica 5", title:"Silikonska žica"}, 
        {src: zice6, alt: "Namotana žica 6", title:"Čelična toplocinčana žica"},
        {src: zice7, alt:"Namotana žica 7", title:"Namotana žice"}, 
        {src: zice8, alt: "Namotana žica 8", title:"Namotana žice"}
    ],
    klijesta:[
    {src:klijesta1, alt:"Upakovana kliješta 1", title:"Upakovana kliješta"}, 
    {src:klijesta2, alt: "Upakovana kliješta 2", title:"Upakovana kliješta"}
],
    ofirmi: [],
    nasiKupci:[]
}

export default imagesObject;