import React from 'react';
import MenuItems from './MenuItems';
import MainContent from './MainContent';
import AboutUs from './AboutUs';


const MainMiddle = (props) => {
    return (
        <div id="main-middle-container">
            <MenuItems 
            handleMainMenuClick={props.handleMainMenuClick}
            handleMenuHoverIn={props.handleMenuHoverIn}
            handleMenuHoverOut={props.handleMenuHoverOut}
            handleSecondaryMenuClick={props.handleSecondaryMenuClick}
            possibleSecondaryMenus={props.possibleSecondaryMenus}
            possibleMainMenus={props.possibleMainMenus}
            selectedMainMenu={props.selectedMainMenu}
            selectedSecondaryMenu={props.selectedSecondaryMenu}
            secondaryMenuVisibility={props.secondaryMenuVisibility}
            />

        {props.selectedMainMenu !== "O nama" ?        <MainContent 
            handleCount={props.handleCount}
            imagesCount={props.imagesCount}
            imgToFullScreen={props.imgToFullScreen}
            selectedMainMenu={props.selectedMainMenu}
            selectedSecondaryMenu={props.selectedSecondaryMenu}
            /> : <AboutUs />}
     
        </div>
    );
}; 

export default MainMiddle;  