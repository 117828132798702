import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import imagesObject from './Images';

const ImageModal = (props) => {
    let imgSource = "";
    let altText= "Token text";
    let pictureTitle = "Token text";

    switch(props.selectedMainMenu){
        case "Olovne plombe":
            imgSource = imagesObject.plombe.olovne[props.imagesCount].src;
            altText = imagesObject.plombe.olovne[props.imagesCount].alt;
            pictureTitle = imagesObject.plombe.olovne[props.imagesCount].title;
        break;
        case "Plastične plombe":    
        imgSource = imagesObject.plombe.kodirane[props.imagesCount].src;
            altText = imagesObject.plombe.kodirane[props.imagesCount].alt;
            pictureTitle = imagesObject.plombe.kodirane[props.imagesCount].title;
        break;

        case "Žice za plombiranje":
            imgSource = imagesObject.zice[props.imagesCount].src;
            altText = imagesObject.zice[props.imagesCount].alt;
            pictureTitle = imagesObject.zice[props.imagesCount].title;
            break;

        case "Kliješta za plombiranje":
            imgSource = imagesObject.klijesta[props.imagesCount].src;
            altText = imagesObject.klijesta[props.imagesCount].alt;
            pictureTitle = imagesObject.klijesta[props.imagesCount].title;
            break;

        case "O nama":
            imgSource = imagesObject.ofirmi[props.imagesCount].src;
            altText = imagesObject.ofirmi[props.imagesCount].alt;
            pictureTitle = imagesObject.ofirmi[props.imagesCount].title;
            break;

        case "Kupci":
            imgSource = "";
            altText = "alt";
            pictureTitle = "Neki tekst"
        break;

        default:
        return false;
    }
    return (
        <div id="modal-container" onClick={props.leaveFullscreen} >
   <div id="modal-holder">
            <div id="left-modal-carousel">
            <FontAwesomeIcon 
                icon={faChevronLeft} onClick={e => props.handleCount(e,'desc')}/>
            </div>
            <div id="modal-picture-holder" onClick={props.leaveFullscreen}>
                <img src={imgSource} alt={altText}  onClick={props.leaveFullscreen}/>
                <div id="picture-modal-title">
    <p>{pictureTitle}</p>
                </div>
            </div>
            <div id="right-modal-carousel">
            <FontAwesomeIcon 
                icon={faChevronRight} onClick={e => props.handleCount(e,'asc')}
              />
            </div>
        </div>
           
        </div>
    );
};

export default ImageModal;