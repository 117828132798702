import imagesObject from './Images';

export const updateMax = (which, secondary) => {

switch(which){
    case "Olovne plombe":
            return imagesObject.plombe.olovne.length -1 ;

    case "Plastične plombe":
            return imagesObject.plombe.kodirane.length - 1;
    
    case "Žice za plombiranje":
        return imagesObject.zice.length - 1;

    case "Kliješta za plombiranje":
        return imagesObject.klijesta.length - 1;

    case "O nama":
        return imagesObject.ofirmi.length - 1;

    case "Kupci":
        return imagesObject.nasiKupci.length - 1;

        default:
        return 0;
}
}

