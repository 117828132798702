import React from 'react';
import PictureContainer from './PictureContainer';
import TextContainer from './TextContainer';
import Tables from './Tables';

const MainContent = (props) => {
    return (
        <div id="main-content-container">
            <PictureContainer
             handleCount={props.handleCount}
             imagesCount={props.imagesCount}
             imgToFullScreen={props.imgToFullScreen}
             selectedMainMenu={props.selectedMainMenu}
             selectedSecondaryMenu={props.selectedSecondaryMenu}
            />
            <div id="right-main-side">
        
            <TextContainer 
            selectedMainMenu={props.selectedMainMenu}
            selectedSecondaryMenu={props.selectedSecondaryMenu}
            />
                <Tables
            selectedMainMenu={props.selectedMainMenu}
            selectedSecondaryMenu={props.selectedSecondaryMenu}
            /> 
               </div>
            </div>
    );
};

export default MainContent;