import React from 'react';

const Tables = (props) => {
let selectedTable;
const olovneTabela = (
  <table>
    <thead>
      <tr>
        <th>Naziv</th>
        <th>Dimenzija plombe u [mm]</th>
        <th>Broj komada u jednom [kg]</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>OB  8</td>
        <td>ϕ  8 x 4</td>
        <td>450</td>
      </tr>
      <tr>
        <td>OB 10</td>
        <td>ϕ10 x 5</td>
        <td>340</td>
      </tr>
      <tr>
        <td>OB 12</td>
        <td>ϕ12 x 6</td>
        <td>200</td>
      </tr>
      <tr>
        <td>OB 14</td>
        <td>ϕ14 x 7</td>
        <td>140</td>
      </tr>
      </tbody>
    </table>)

const kodiranaTabela = (
<table>
<thead>
      <tr>
        <th>Tip</th>
        <th>Dimenzije</th>
        <th>Težina</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>PP 20</td>
        <td>20x20x6</td>
        <td>4,7 g</td>
      </tr>
      </tbody>
</table>
)

const klijestaTabela = (
<table>
  <thead>
<tr>
<th>Tip</th>
<th>Dužina kraka</th>
<th colSpan="3">Prečnik plombe</th>
<th>Prečnik žiga</th>
</tr>
</thead>
<tbody>
<tr>
<td>KPZ 125</td>
<td>125 mm</td>
<td rowSpan="2">ϕ 8mm</td>
<td rowSpan="2">ϕ 10mm</td>
<td rowSpan="2">ϕ 12mm</td>

<td>ϕ 9mm</td>
</tr>  
</tbody>
</table>
)
switch(props.selectedMainMenu){
  case "Olovne plombe":
    selectedTable = olovneTabela;
  break;

  case "Plastične plombe":
    selectedTable = kodiranaTabela;
  break;

  case "Kliješta za plombiranje":
    selectedTable = klijestaTabela;
    break;

  default:
    return null;
}


    return (
        <div id="table-container">
       {selectedTable}
    </div>
 
    );
};

export default Tables;