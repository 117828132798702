import React from 'react';
import oFirmi1 from '../images/ofirmi1.png';
import oFirmi2 from '../images/ofirmi2.bmp';

const AboutUs = (props) => {

    return (
        <div id="main-content-container">
            <div id="about-us-holder">
        <div>
        Firma je osnovana 12.02. 1993. godine i u početku se bavila samo proizvodnjom olovnih plombi i žice za plombiranje. Sada proizvodimo četiri veličine olovnih plombi, više vrsta žica za plombiranje, kliješta za plombiranje, plastične plombe sa više tipova označavanja kao i graviranja u metalima i ostalim materijama. 

Imamo UV štampać Roland, laserski graver i mehaničku gravirku za označavanje svih vrsta plombi i graviranje kliješta.

Posjedujemo sistem upravljanja kvalitetom ISO 9001 - 2008 od TUV NORD i certifikate za kvalitet naših proizvoda od strane Univerziteta u Zenici, Instituta 'Kemal Kapetanović'. 

Locirani smo 4km od grada u slivu rijeke Trstionice (mapa) i radimo u novoizgrađenom radioničkom objektu od cca 200m2 sa svim potrebnim pratećim prostorima.
</div>
<div id="secondary-picture-holder">
             <img src={oFirmi1} alt={"Nord certfikat"}  />
        <img src={oFirmi2} alt={"Zenički certfikat"}  />
        </div>
        </div>
    </div>
 
    );
};

export default AboutUs;